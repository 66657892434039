import {
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import "./Simulator.css";
import General from "./General/General";
import TargetValue from "./TargetValue/TargetValue";

function Simulator(props) {
  const [type, setType] = useState("general");

  useEffect(() => {}, []);

  function handleChangeType(event) {
    setType(event.currentTarget.value);
  }

  return (
    <div className="data-div">
      <Card sx={{ margin: "10px" }}>
        <FormControl sx={{ margin: "10px" }}>
          <FormLabel>Test Type</FormLabel>
          <RadioGroup
            row
            name="test-type"
            value={type}
            onChange={handleChangeType}
          >
            <Tooltip title="Tests that return simple statistical data given an amount of cards drawn.">
              <FormControlLabel
                value="general"
                control={<Radio />}
                label="General"
              ></FormControlLabel>
            </Tooltip>
            <Tooltip title="Tests that use the Sunrise Paradox rules to return statistical data. The simulation checks the number of cards drawn that are less than or equal to a given target value. One card is a Normal success, two cards is a Strong success, three cards or more is an Extreme Success.">
              <FormControlLabel
                value="targetValue"
                control={<Radio />}
                label="Target Value"
              ></FormControlLabel>
            </Tooltip>
          </RadioGroup>
        </FormControl>
      </Card>
      <div>
        {type === "general" && <General />}
        {type === "targetValue" && <TargetValue />}
      </div>
    </div>
  );
}

export default Simulator;
