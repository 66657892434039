import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Simulator from "./Simulator/Simulator";

let cardSimTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#3946bf",
      dark: "#353535",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#4786d4",
      contrastText: "#f7f7f7",
    },
    background: {
      default: "#000000",
      paper: "#ffffff",
    },
    text: {
      primary: "#2F2F2F",
      secondary: "#8787872",
      disabled: "rgba(226,225,225,0.37)",
      hint: "rgba(210,206,206,0.5)",
    },
  },
});

//responsiveFontSizes(card);

function App() {
  return (
    <ThemeProvider theme={cardSimTheme}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Simulator />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
