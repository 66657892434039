import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import DrawGraphs from "./DrawGraphs/DrawGraphs";

function TargetValue(props) {
  const [waiting, setWaiting] = useState(false);
  const [deck, setDeck] = useState([]);
  const [checkTV, setCheckTV] = useState(true);
  const [checkEmpowered, setCheckEmpowered] = useState(true);
  const [samples, setSamples] = useState(1);
  const [usedSample, setUsedSample] = useState(1);
  const [sampleError, setSampleError] = useState(false);
  const [sampleHelp, setSampleHelp] = useState("");
  const [draw, setDraw] = useState(1);
  const [drawError, setDrawError] = useState(false);
  const [drawHelp, setDrawHelp] = useState("");
  const [tv, setTV] = useState(0);
  const [tvError, setTVError] = useState(false);
  const [tvHelp, setTVHelp] = useState("");
  const [normal, setNormal] = useState(0);
  const [strong, setStrong] = useState(0);
  const [extreme, setExtreme] = useState(0);
  const [paradox, setParadox] = useState(0);
  const [glitch, setGlitch] = useState(0);
  const [resultSet, setResultSet] = useState([]);

  useEffect(() => {
    let temp = [];
    for (let i = 1; i < 14; ++i) {
      for (let j = 1; j < 5; ++j) {
        temp.push({
          card: i,
          suit: j,
        });
      }
    }
    setDeck(temp);
  }, []);

  function validate() {
    let valid = true;
    const regex = new RegExp(/[^\d]/);
    if (regex.test(draw) || draw < 1) {
      setDrawError(true);
      setDrawHelp("Draw is not a valid positive integer.");
      valid = false;
    }
    if (regex.test(samples) || samples < 1) {
      setSampleError(true);
      setSampleHelp("Samples is not a valid positive integer.");
      valid = false;
    }
    if (regex.test(tv)) {
      setTVError(true);
      setTVHelp("Target Value is not a valid positive integer.");
      valid = false;
    } else if (checkTV && (parseInt(tv) > 13 || parseInt(tv) < 1)) {
      setTVError(true);
      setTVHelp("Target Value is not between 1 and 13");
      valid = false;
    }
    valid ? setWaiting(true) : setWaiting(false);
    if (valid) {
      setDrawHelp("");
      setSampleHelp("");
      setSampleError(false);
      setDrawError(false);
      setTVError(false);
      setTVHelp("");
    }
    return valid;
  }

  function handleSubmit() {
    if (validate()) {
      setUsedSample(samples);
      const worker = new Worker(
        new URL("../../assets/tvCalc.js", import.meta.url)
      );
      worker.postMessage({
        draw: draw,
        samples: samples,
        deck: deck,
        tv: tv,
        checkEmpowered: checkEmpowered,
      });
      worker.onmessage = ({ data: { result } }) => {
        if (result.length === 1) {
          setNormal(result[0].normal);
          setStrong(result[0].strong);
          setExtreme(result[0].extreme);
          setGlitch(result[0].glitch);
          setParadox(result[0].paradox);
          setWaiting(false);
        } else if (result.length > 1) {
          setResultSet(result);
          console.log(resultSet);
          setWaiting(false);
        }
      };
    }
  }

  /*<TextField
          sx={{ margin: "10px" }}
          label="Normal Successes"
          value={normal}
        ></TextField>
        <TextField
          sx={{ margin: "10px" }}
          label="Strong Successes"
          value={strong}
        ></TextField>
        <TextField
          sx={{ margin: "10px" }}
          label="Extreme Successes"
          value={extreme}
        ></TextField>
        {checkEmpowered && (
          <>
            <TextField
              sx={{ margin: "10px" }}
              label="Glitches"
              value={glitch}
            ></TextField>
            <TextField
              sx={{ margin: "10px" }}
              label="Paradoxes"
              value={paradox}
            ></TextField>
          </>
        )}
        */

  return (
    <Container sx={{ width: "fit-content" }}>
      <Card
        className="selection-card"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          alignContent: "center",
          margin: "10px",
        }}
      >
        <CardHeader title="Parameters"></CardHeader>
        <FormGroup>
          <FormLabel>Options</FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkTV}
                onChange={() => {
                  setCheckTV(!checkTV);
                  setTV(0);
                }}
              />
            }
            label="Specific Target Value?"
          />
          <Tooltip
            title="An empowered draw gives face cards special effects that increase the probablility of successes. Jacks redraw all adjacent cards, Queens redraw all adjacent successes, and Kings redraw all adjacent failures. Aces draw new hands and the one with more successes is kept. Drawing 2 aces at any time is an automatic extreme success."
            enterDelay={500}
            disableInteractive
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkEmpowered}
                  onChange={() => {
                    setCheckEmpowered(!checkEmpowered);
                  }}
                />
              }
              label="Empowered Draws?"
            />
          </Tooltip>
        </FormGroup>
        <TextField
          required
          sx={{ margin: "10px" }}
          label="Number of Draws"
          value={draw}
          onChange={(event) => {
            setDraw(event.currentTarget.value);
          }}
          error={drawError}
          helperText={drawHelp}
        ></TextField>
        <TextField
          required
          sx={{ margin: "10px" }}
          label="Samples"
          value={samples}
          onChange={(event) => {
            setSamples(event.currentTarget.value);
          }}
          error={sampleError}
          helperText={sampleHelp}
        ></TextField>
        {checkTV && (
          <TextField
            required
            sx={{ margin: "10px" }}
            label="Target Value"
            value={tv}
            onChange={(event) => {
              setTV(event.currentTarget.value);
            }}
            error={tvError}
            helperText={tvHelp}
          ></TextField>
        )}
        {!waiting && (
          <Button
            variant="contained"
            sx={{ margin: "10px" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
        {waiting && <CircularProgress sx={{ margin: "10px" }} />}
      </Card>
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          alignContent: "center",
          margin: "10px",
        }}
      >
        <CardHeader title="Results"></CardHeader>
        <CardContent>
          {checkTV && (
            <TableContainer sx={{ margin: "5px", minWidth: "fit-contents" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Result Type</TableCell>
                    <TableCell>Number</TableCell>
                    <TableCell>Percent of Draws</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Normal
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {normal}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {((normal / samples) * 100).toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Strong
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {strong}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {((strong / samples) * 100).toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Extreme
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {extreme}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {((extreme / samples) * 100).toFixed(2)}
                    </TableCell>
                  </TableRow>
                  {checkEmpowered && (
                    <>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Glitches
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {glitch}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {((glitch / samples) * 100).toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Paradoxes
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {paradox}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {((paradox / samples) * 100).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>
      {!checkTV && (
        <DrawGraphs resultSet={resultSet} samples={usedSample}></DrawGraphs>
      )}
    </Container>
  );
}

export default TargetValue;
