import { useEffect, useState } from "react";
import CanvasJSReact from "../../../assets/canvasjs.react";
import "./DrawGraphs.css";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function DrawGraphs(props) {
  const [normalChart, setNormalChart] = useState([]);
  const [strongChart, setStrongChart] = useState([]);
  const [extremeChart, setExtremeChart] = useState([]);

  useEffect(() => {
    console.log(props.resultSet);
    if (props.resultSet.length !== 0) {
      const normalData = {
        animationEnabled: true,
        title: {
          text: "Percent of Normal Successes",
        },
        data: [
          {
            type: "line",
            dataPoints: [
              { x: 1, y: (props.resultSet[0][0].normal / props.samples) * 100 },
              { x: 2, y: (props.resultSet[1][0].normal / props.samples) * 100 },
              { x: 3, y: (props.resultSet[2][0].normal / props.samples) * 100 },
              { x: 4, y: (props.resultSet[3][0].normal / props.samples) * 100 },
              { x: 5, y: (props.resultSet[4][0].normal / props.samples) * 100 },
              { x: 6, y: (props.resultSet[5][0].normal / props.samples) * 100 },
              { x: 7, y: (props.resultSet[6][0].normal / props.samples) * 100 },
              { x: 8, y: (props.resultSet[7][0].normal / props.samples) * 100 },
              { x: 9, y: (props.resultSet[8][0].normal / props.samples) * 100 },
              {
                x: 10,
                y: (props.resultSet[9][0].normal / props.samples) * 100,
              },
              {
                x: 11,
                y: (props.resultSet[10][0].normal / props.samples) * 100,
              },
              {
                x: 12,
                y: (props.resultSet[11][0].normal / props.samples) * 100,
              },
              {
                x: 13,
                y: (props.resultSet[12][0].normal / props.samples) * 100,
              },
            ],
          },
        ],
        axisX: {
          title: "Target Value",
          interval: 1,
        },
        axisY: {
          title: "Percent",
          suffix: "%",
          minimum: 0,
          maximum: 100,
          interval: 10,
        },
      };

      const strongData = {
        animationEnabled: true,
        title: {
          text: "Percent of Strong Successes",
        },
        data: [
          {
            type: "line",
            dataPoints: [
              { x: 1, y: (props.resultSet[0][0].strong / props.samples) * 100 },
              { x: 2, y: (props.resultSet[1][0].strong / props.samples) * 100 },
              { x: 3, y: (props.resultSet[2][0].strong / props.samples) * 100 },
              { x: 4, y: (props.resultSet[3][0].strong / props.samples) * 100 },
              { x: 5, y: (props.resultSet[4][0].strong / props.samples) * 100 },
              { x: 6, y: (props.resultSet[5][0].strong / props.samples) * 100 },
              { x: 7, y: (props.resultSet[6][0].strong / props.samples) * 100 },
              { x: 8, y: (props.resultSet[7][0].strong / props.samples) * 100 },
              { x: 9, y: (props.resultSet[8][0].strong / props.samples) * 100 },
              {
                x: 10,
                y: (props.resultSet[9][0].strong / props.samples) * 100,
              },
              {
                x: 11,
                y: (props.resultSet[10][0].strong / props.samples) * 100,
              },
              {
                x: 12,
                y: (props.resultSet[11][0].strong / props.samples) * 100,
              },
              {
                x: 13,
                y: (props.resultSet[12][0].strong / props.samples) * 100,
              },
            ],
          },
        ],
        axisX: {
          title: "Target Value",
          interval: 1,
        },
        axisY: {
          title: "Percent",
          suffix: "%",
          minimum: 0,
          maximum: 100,
          interval: 10,
        },
      };

      const extremeData = {
        animationEnabled: true,
        title: {
          text: "Percent of Extreme Successes",
        },
        data: [
          {
            type: "line",
            dataPoints: [
              {
                x: 1,
                y: (props.resultSet[0][0].extreme / props.samples) * 100,
              },
              {
                x: 2,
                y: (props.resultSet[1][0].extreme / props.samples) * 100,
              },
              {
                x: 3,
                y: (props.resultSet[2][0].extreme / props.samples) * 100,
              },
              {
                x: 4,
                y: (props.resultSet[3][0].extreme / props.samples) * 100,
              },
              {
                x: 5,
                y: (props.resultSet[4][0].extreme / props.samples) * 100,
              },
              {
                x: 6,
                y: (props.resultSet[5][0].extreme / props.samples) * 100,
              },
              {
                x: 7,
                y: (props.resultSet[6][0].extreme / props.samples) * 100,
              },
              {
                x: 8,
                y: (props.resultSet[7][0].extreme / props.samples) * 100,
              },
              {
                x: 9,
                y: (props.resultSet[8][0].extreme / props.samples) * 100,
              },
              {
                x: 10,
                y: (props.resultSet[9][0].extreme / props.samples) * 100,
              },
              {
                x: 11,
                y: (props.resultSet[10][0].extreme / props.samples) * 100,
              },
              {
                x: 12,
                y: (props.resultSet[11][0].extreme / props.samples) * 100,
              },
              {
                x: 13,
                y: (props.resultSet[12][0].extreme / props.samples) * 100,
              },
            ],
          },
        ],
        axisX: {
          title: "Target Value",
          interval: 1,
        },
        axisY: {
          title: "Percent",
          suffix: "%",
          minimum: 0,
          maximum: 100,
          interval: 10,
        },
      };
      setNormalChart(normalData);
      setStrongChart(strongData);
      setExtremeChart(extremeData);
    }
  }, [props.resultSet, props.samples]);

  return (
    <>
      <CanvasJSChart class="graphs" options={normalChart} />
      <CanvasJSChart class="graphs" options={strongChart} />
      <CanvasJSChart class="graphs" options={extremeChart} />
    </>
  );
}

export default DrawGraphs;
