import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";

function General(props) {
  const [stats, setStats] = useState("avg");
  const [samples, setSamples] = useState(1);
  const [sampleError, setSampleError] = useState(false);
  const [sampleHelp, setSampleHelp] = useState("");
  const [draw, setDraw] = useState(1);
  const [drawError, setDrawError] = useState(false);
  const [drawHelp, setDrawHelp] = useState("");
  const [deck, setDeck] = useState([]);
  const [calcResult, setCalcResult] = useState("");
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    let temp = [];
    for (let i = 1; i < 14; ++i) {
      for (let j = 1; j < 5; ++j) {
        temp.push({
          card: i,
          suit: j,
        });
      }
    }
    setDeck(temp);
  }, []);

  function validate() {
    let valid = true;
    const regex = new RegExp(/[^\d]/);
    if (regex.test(draw) || draw < 1) {
      setDrawError(true);
      setDrawHelp("Draw is not a valid integer.");
      valid = false;
    }
    if (regex.test(samples) || samples < 1) {
      setSampleError(true);
      setSampleHelp("Samples is not a valid integer.");
      valid = false;
    }
    valid ? setWaiting(true) : setWaiting(false);
    if (valid) {
      setDrawHelp("");
      setSampleHelp("");
      setSampleError(false);
      setDrawError(false);
    }
    return valid;
  }

  function handleSubmit() {
    if (validate()) {
      const worker = new Worker(
        new URL("../../assets/generalCalc.js", import.meta.url)
      );
      worker.postMessage({
        type: stats,
        draw: draw,
        samples: samples,
        deck: deck,
      });
      worker.onmessage = ({ data: { result } }) => {
        setCalcResult(result);
        setWaiting(false);
      };
    }
  }

  return (
    <>
      <Card
        className="selection-card"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          alignContent: "center",
          margin: "20px",
        }}
      >
        <CardHeader title="Parameters"></CardHeader>
        <FormControl sx={{ margin: "10px" }}>
          <FormLabel>Test Type</FormLabel>
          <RadioGroup
            row
            name="Stats"
            value={stats}
            onChange={(event) => {
              setStats(event.currentTarget.value);
            }}
          >
            <Tooltip title="Returns the average value of the drawn cards.">
              <FormControlLabel
                value="avg"
                control={<Radio />}
                label="Average"
              ></FormControlLabel>
            </Tooltip>
            <Tooltip title="Returns the Max value of the drawn cards.">
              <FormControlLabel
                value="max"
                control={<Radio />}
                label="Max"
              ></FormControlLabel>
            </Tooltip>
            <Tooltip title="Returns the Minimum value of the drawn cards.">
              <FormControlLabel
                value="min"
                control={<Radio />}
                label="Min"
              ></FormControlLabel>
            </Tooltip>
          </RadioGroup>
        </FormControl>
        <TextField
          required
          sx={{ margin: "10px" }}
          label="Number of Draws"
          value={draw}
          onChange={(event) => {
            setDraw(event.currentTarget.value);
          }}
          error={drawError}
          helperText={drawHelp}
        ></TextField>
        <TextField
          required
          sx={{ margin: "10px" }}
          label="Samples"
          value={samples}
          onChange={(event) => {
            setSamples(event.currentTarget.value);
          }}
          error={sampleError}
          helperText={sampleHelp}
        ></TextField>
        {!waiting && (
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ margin: "10px" }}
          >
            Submit
          </Button>
        )}
        {waiting && <CircularProgress sx={{ margin: "10px" }} />}
      </Card>
      <Card>
        <CardHeader title="Results"></CardHeader>
        <TextField
          sx={{ margin: "20px" }}
          label="Average"
          value={calcResult}
        ></TextField>
      </Card>
    </>
  );
}

export default General;
